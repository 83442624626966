import { ManualActionRequest } from "@/manual-actions/types"
import { RelayAuthError, ClustersNotFoundError, ManualActionFailedError } from "@/manual-actions/errors"
import { useClusterAuth } from "@/store/cluster-auth/composition";
import { trackEvent } from "@/analytics"

const RELAY_HOST = (window as any).ENV_RELAY_HOST as any;
const MANUAL_ACTIONS_SYNC_ENDPOINT = process.env.VUE_APP_MANUAL_ACTIONS_ENDPOINT || "/integrations/sync/actions"
const MANUAL_ACTIONS_ASYNC_ENDPOINT = process.env.VUE_APP_MANUAL_ACTIONS_ENDPOINT || "/integrations/async/actions"

enum RelayErrorStatus {
  AUTH = 401,
  CLUSTER_NOT_FOUND = 404
}

export type ManualActionResponse = {
  request: ManualActionRequest
  data: any
  error: Error | undefined
}

export default async function sendManualActionsToRelay(sessionToken: string, actionRequests: ManualActionRequest[]): Promise<ManualActionResponse[]> {
  if (!sessionToken) {
    throw new Error("Cannot run actions session token are empty")
  }

  const actionPromises = actionRequests.map(actionRequest => {
    return _getActionPromise(sessionToken, actionRequest)
  })

  const responses = await Promise.allSettled(actionPromises)

  return responses.map((resp, i) => {
    const request = actionRequests[i]

    // Response is OK
    if(resp.status === "fulfilled")
      return {
        request,
        data: resp.value,
        error: undefined
      }

    // On Auth errors we fail the entire thing
    if(resp.reason instanceof RelayAuthError)
      throw new RelayAuthError()

    trackResponseError(resp.reason, request.actionName, request.clusterName)

    // Other errors are mapped to their original requests
    return {
      request,
      data: undefined,
      error: resp.reason
    }
  })
}

async function _getActionPromise(sessionToken: string, actionRequest: ManualActionRequest): Promise<any> {
  let response: Response

  try {
    response = await _sendActionRequest(sessionToken, actionRequest)
  } catch(err) {
    // Means either a network failure or something prevented the request from completing.
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    throw new Error(`Manual action was not sent due to an unknown error. Original error - ${err}`)
  }

  const responseJSON = await response.json()
  if(response.ok && responseJSON.data.success) return responseJSON
  if(response.status === RelayErrorStatus.AUTH) throw new RelayAuthError()
  if(response.status === RelayErrorStatus.CLUSTER_NOT_FOUND) throw new ClustersNotFoundError(actionRequest.clusterName)
  throw new ManualActionFailedError(
    actionRequest,
    responseJSON.data?.error_code ?? responseJSON.error_code,
    responseJSON.data?.msg ?? responseJSON.msg
  )
}

async function _sendActionRequest(sessionToken: string, actionRequest: ManualActionRequest): Promise<Response> {
  //we stringify and parse here inorder to create a valid "clean" object. Object may contain Vue observer array
  //which is not compatible with our SortKeys object implementation.
  const actionParams = JSON.parse(JSON.stringify(actionRequest.actionParams))

  const noSinks = actionRequest.options.sinks.length === 0

  const actionData = {
    account_id: actionRequest.accountId,
    cluster_name: actionRequest.clusterName,
    timestamp: Math.round(Date.now() / 1000),
    action_name: actionRequest.actionName,
    action_params: actionParams,
    origin: "Robusta UI",
    ...!noSinks && { sinks: actionRequest.options.sinks }
  }

  let requestData = {}
  const clusterAuth = useClusterAuth()
  if (!clusterAuth.isActionsAuthOnCluster(actionRequest.clusterName, [actionRequest.actionName])){
    throw new Error(`Manual action ${actionRequest.actionName} not supported on cluster ${actionRequest.clusterName}`)
  }
  if (clusterAuth.clusterHasLightActionSupport(actionRequest.clusterName)) {
    // light action support
    requestData = {
      no_sinks: noSinks,
      session_token: sessionToken,
      body: actionData
    }
  } else {
    throw new Error("Cannot run actions on unsupported cluster")
  }

  // Note: If there's HTTP error, the promise won't reject, but return a
  // Response with the corresponding status
  const endpoint = actionRequest.options.async ? MANUAL_ACTIONS_ASYNC_ENDPOINT : MANUAL_ACTIONS_SYNC_ENDPOINT
  const url = RELAY_HOST + endpoint
  return await fetch(url, {
    method: "POST",
    headers: {
      "Access-Control-Request-Method": "POST",
      "Access-Control-Request-Headers": "Content-Type",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(requestData)
  })
}



function trackResponseError(error: Error, action: string, cluster: string) {
  const props: Record<string, any> = {
    error: error.name,
    errorMessage: error?.message ?? "",
    action,
    cluster
  }

  if(error instanceof ManualActionFailedError) {
    props.errorMessage = error.error_message ?? ""
    props.errorCode = error.error_code ?? ""
  }

  trackEvent("Manual Action Response Error", props)
}
