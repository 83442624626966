import { ref, computed } from 'vue'
import { IConfig as SWRVConfig } from 'swrv'
import { DateTime as LuxonDateTime } from "luxon"
import { parseSupabaseDate } from "@/store/supabase-client"
import useGetDataForActiveAccount, { IAPIResponse, generateFilterBasedCacheKey } from "@/api/get-data-for-active-account";
import supabase from "@/store/supabase-client";
import { fetchClustersStatuses, ClustersStatusesQueryOptions, ClustersStatus } from "./data";
import semver from 'semver'

export const DEV_CLUSTER_VERSION = '0.0.0'
export const MIN_CLUSTER_COMPARISON_VERSION = '0.9.12'
export const MIN_CLUSTER_HEALTH_VERSION = '0.9.13'
export const MIN_CLUSTER_POD_STATS_VERSION = '0.10.7'
export const MIN_ALERT_SILENCING_VERSION = '0.9.16'
export const MIN_JOBS_VERSION = '0.10.2'
export const MIN_PROMETHEUS_ENRICHER_VERSION = '0.10.6'
export const MIN_LIGHT_ACTIONS_VERSION = '0.10.9'
export const MIN_JOB_DETAILS_VERSION = '0.10.11'
export const MIN_NODE_PODS_VERSION = '0.10.11'
export const MIN_NODE_EVENTS_VERSION = '0.10.12'
export const MIN_APPS_MULTICONTAINER_VERSION = '0.10.15'
export const MIN_KRR_VERSION = '0.10.15'
export const MIN_POPEYE_VERSION = '0.10.15'
export const MIN_CUSTER_VERSION_FOR_LIST_RESOURCE_NAMES = '0.10.15'
export const MIN_UI_ACTION_VERSION = '0.10.18'
export const MIN_NODE_JOB_UI_ACTIONS_VERSION = '0.10.22'
export const MIN_MONETISATION_VERSION = '0.10.24'
export const MIN_PROMETHEUS_QUERY_VERSION = '0.10.26'
export const MIN_CLUSTER_ALERT_RULE_VERSION = '0.10.27'
export const MIN_HOLMES_SERVICE_VERSION = '0.17.0'
export const MIN_HOLMES_INSTRUCTIONS_VERSION = '0.17.0'
export const MIN_HOLMES_CHAT_VERSION = '0.17.0'
export const MIN_SIMULATE_ALERT_VERSION = '0.17.0'

export const CLUSTER_HEALTH_CHECK_HOURS = 8;

export function isClusterAlive(cluster : ClustersStatus) : boolean {
  const clusterTime = LuxonDateTime.fromJSDate(parseSupabaseDate(cluster.updated_at!));
  const hoursSinceLastUpdate = LuxonDateTime.now().diff(clusterTime, ["hours"]).toObject()["hours"];

  const isActive = cluster.active === null ? true : cluster.active
  if(!isActive) {
    return false
  }

	if(cluster.version === '0.0.0' || semver.gt(cluster.version!, "0.9.12")) {
    return hoursSinceLastUpdate < 1; // Above 0.9.12, clusters send a heart beat every 15 minutes. We allow grace of extra 45 minutes.
	}

  return hoursSinceLastUpdate < 7; // clusters send a heart beat every 3 hours.
}

export function isClusterSupported(minVersion: string, status?: ClustersStatus): boolean {
  if(!status || !status.version || status.version === 'unknown') return false

  const isDev = semver.eq(status.version, DEV_CLUSTER_VERSION)
  if(isDev) return true

  const isAlpha = status.version.includes("alpha")
  if(isAlpha) return true

  const isOutdated = semver.lt(status.version, minVersion)
  return !isOutdated
}

export function isAlertManagerConnectedToCluster(status: ClustersStatus): boolean {
  if(!status.created_at) return false
  const createdAt = parseSupabaseDate(status.created_at)
  const minutesSinceCreation = (Date.now() - createdAt.getTime()) / (1000 * 60)
  if(minutesSinceCreation < 30) return true

  if(!status.last_alert_at) return false
  const lastAlertAt = parseSupabaseDate(status.last_alert_at)
  const daysSinceLastAlert = (Date.now() - lastAlertAt.getTime()) / (1000 * 60 * 60 * 24)
  if(daysSinceLastAlert < 7) return true

  return false
}

export function useClustersStatuses(options?: Partial<ClustersStatusesQueryOptions & { swrvConfig: SWRVConfig }>) {
  const { swrvConfig, ...fetcherOptions } = { ...options }
  const cacheKey = Object.keys(fetcherOptions).length
    ? `clusters-statuses:${generateFilterBasedCacheKey(fetcherOptions)}`
    : 'clusters-statuses'
  const fetcher = () => fetchClustersStatuses(supabase.accountId!, fetcherOptions)

  return useGetDataForActiveAccount(cacheKey, fetcher, swrvConfig)
}

export function useCluster(initClusterName?: string) {
  const clusterName = ref<string | undefined>(initClusterName)
  const { data: allClusterStatuses } = useClustersStatuses()
  const clusterStatus = computed(() => {
    return allClusterStatuses.value?.filter(c => c.cluster_id === clusterName.value)[0]
  })

  function setClusterName(name) {
    if(clusterName.value === name)
      return
    clusterName.value = name
  }

  function isCompatible(minVersion) {
    if(!clusterStatus.value)
      return undefined
    return isClusterSupported(minVersion, clusterStatus.value)
  }

  return {
    setClusterName,
	  info: clusterStatus,
    isComparisonCompatible: computed(() => isCompatible(MIN_CLUSTER_COMPARISON_VERSION)),
    isHealthCompatible: computed(() => isCompatible(MIN_CLUSTER_HEALTH_VERSION)),
    isSilencingCompatible: computed(() => isCompatible(MIN_ALERT_SILENCING_VERSION)),
    isJobsCompatible: computed(() => isCompatible(MIN_JOBS_VERSION)),
    isResourceGraphsCompatible: computed(() => isCompatible(MIN_PROMETHEUS_ENRICHER_VERSION)),
    isJobDetailsCompatible: computed(() => isCompatible(MIN_JOB_DETAILS_VERSION)),
    isNodePodsCompatible: computed(() => isCompatible(MIN_NODE_PODS_VERSION)),
    isNodeEventsCompatible: computed(() => isCompatible(MIN_NODE_EVENTS_VERSION)),
    isAppsMultiContainerCompatible: computed(() => isCompatible(MIN_APPS_MULTICONTAINER_VERSION)),
    isMinPopEyeCompatible: computed(() => isCompatible(MIN_POPEYE_VERSION)),
    isClusterListResourceNamesCompatible: computed(() => isCompatible(MIN_CUSTER_VERSION_FOR_LIST_RESOURCE_NAMES)),
    isMinUIActionCompatible: computed(() => isCompatible(MIN_UI_ACTION_VERSION)),
	  isMinPrometheusQuery: computed(() => isCompatible(MIN_PROMETHEUS_QUERY_VERSION))
  }
}

export function getCompatibilityFields(cluster) {
	return {
		isComparisonCompatible: isClusterSupported(MIN_CLUSTER_COMPARISON_VERSION, cluster),
		isHealthCompatible: isClusterSupported(MIN_CLUSTER_HEALTH_VERSION, cluster),
		isSilencingCompatible: isClusterSupported(MIN_ALERT_SILENCING_VERSION, cluster),
		isJobsCompatible: isClusterSupported(MIN_JOBS_VERSION, cluster),
		isResourceGraphsCompatible: isClusterSupported(MIN_PROMETHEUS_ENRICHER_VERSION, cluster),
		isJobDetailsCompatible: isClusterSupported(MIN_JOB_DETAILS_VERSION, cluster),
		isNodePodsCompatible: isClusterSupported(MIN_NODE_PODS_VERSION, cluster),
		isNodeEventsCompatible: isClusterSupported(MIN_NODE_EVENTS_VERSION, cluster),
		isAppsMultiContainerCompatible: isClusterSupported(MIN_APPS_MULTICONTAINER_VERSION, cluster),
		isMinPopEyeCompatible: isClusterSupported(MIN_POPEYE_VERSION, cluster),
		isClusterListResourceNamesCompatible: isClusterSupported(MIN_CUSTER_VERSION_FOR_LIST_RESOURCE_NAMES, cluster),
    isClusterAlertRuleCompatible: isClusterSupported(MIN_CLUSTER_ALERT_RULE_VERSION, cluster),
		isMinUIActionCompatible: computed(() => isClusterSupported(MIN_UI_ACTION_VERSION, cluster)),
		isMinPrometheusQuery: computed(() => isClusterSupported(MIN_PROMETHEUS_QUERY_VERSION, cluster)),
	}
}

export function useLiveClusters(): IAPIResponse<string[]> {
  const clusterStatuses = useClustersStatuses()

  return {
    ...clusterStatuses,
    data: computed(() => {
      return clusterStatuses.data.value?.filter(isClusterAlive).map(s => s.cluster_id)
    })
  }
}

export function prepareFinalClusters(clusterStatuses): any {
  if (!clusterStatuses.isValidating.value && clusterStatuses.data.value) {
    return clusterStatuses.data.value
      ?.map((c) => {
        return {
          cluster_id: c.cluster_id,
          isLive: isClusterAlive(c),
          supportedResourceNames: isClusterSupported(MIN_CUSTER_VERSION_FOR_LIST_RESOURCE_NAMES, c),
        };
      })
      .sort((a, b) => a?.cluster_id?.localeCompare(b?.cluster_id))
      .sort((a, b) => (b.isLive ? 1 : 0) - (a.isLive ? 1 : 0));
  }
  return [];
}
