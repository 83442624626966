import { v4 as uuidv4 } from 'uuid'

const CRLF_LINE_BREAK = "\r\n"; // Windows
const LF_LINE_BREAK = "\n"; // Unix
const CR_LINE_BREAK = "\r"; // Mac
const LINE_BREAKS = [CRLF_LINE_BREAK, LF_LINE_BREAK, CR_LINE_BREAK];
const lineBreakRegexp = new RegExp(LINE_BREAKS.join("|"));

export const splitTextIntoLines = (text: string) => text.split(lineBreakRegexp);

export const replaceLinksWithHTMLTags = (s: string) => 
  s.replace(
    /(http(s)?:\/\/\S+)/gm,
    '<a href="$1" target="_blank" rel="nofollow">$1</a>'
  )

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export function capitalizeFirst(str: string) : string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function findAllIndices(str: string, substr: string, caseSensitive = false): number[] {
  const escapedSubstr = substr.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  const re = new RegExp(escapedSubstr, caseSensitive ? "g" : "gi")
  // @ts-ignore
  return [...str.matchAll(re)].map(m => m.index)
}

export function shortenString(line: string, strLength = 10): string {
  const lineLength = line.split('').length

  if ( lineLength <= strLength ) return line

  return `${line.slice(0, strLength - 3)}...${line.slice(lineLength - 3, lineLength)}`
}

export function fixChars(line: string, strLength = 40): string {
  if (strLength >= 0 && line.length >= strLength) {
    return line.slice(0, strLength - 3) + `...`;
  }
  return line;
}

export function generateRandomUUID() {
  try {
    return window.crypto.randomUUID()
  } catch {
    return uuidv4()
  }
}

export function triggerTime(time: string): string {
  if (time) {
    if (time.includes('ms')) {
      return time.replace('ms', ' msec');
    } else if (time.includes('s')) {
      return time.replace('s', ' sec');
    } else if (time.includes('m')) {
      return time.replace('m', ' min');
    } else if (time.includes('h')) {
      return time.replace('h', ' hr');
    } else if (time.includes('d')) {
      return time.replace('d', ' day');
    } else if (time.includes('w')) {
      return time.replace('w', ' wk');
    } else if (time.includes('y')) {
      return time.replace('y', ' yr');
    }
    return time;
  }
  return '';
}
