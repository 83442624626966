import { DateTime } from "luxon"
import { Finding, FindingPriority } from "@/store/findings/data"
import { FindingName, FindingType } from "@/store/findings/types"
import { getMinFiringDate } from "@/store/findings/v2/helpers"

export function getUniqueClusters(findings: Finding[]): string[] {
  const clusters = new Set()

  for(const finding of findings) {
    finding.subject_cluster && clusters.add(finding.subject_cluster)
  }

  return Array.from(clusters) as string[]
}

export function isSingularFinding(finding: Finding) {
  return finding.source !== "prometheus"
}

export function getCorrectedAlertName(originalName: string) {
  if ([
    "ConfigurationChange/KubernetesResource/Change",
    "ConfigurationChange/ExternalResource/Change"
  ].includes(originalName))
    return FindingName.CHANGE
  if (["Generic Change", "GenericChange"].includes(originalName))
    return FindingName.GENERIC_FINDING
  if (["job_failure", "Job Failure"].includes(originalName))
    return FindingName.JOB_FAILURE_LOG
  return originalName
}

export function sortFindingsByStartDate(findings: Finding[]) {
  return findings.sort((f1, f2) => f1.start_date.getTime() - f2.start_date.getTime())
}

export function groupFindingsBy(findings: Finding[], getKey: (f: Finding) => string): Record<string, Finding[]> {
  const groups: Record<string, Finding[]> = {}

  findings.forEach(f => {
    const key = getKey(f)
    if(key in groups)
      groups[key].push(f)
    else
      groups[key] = [f]
  })

  return groups
}

export function getEndTimeForDurationFinding(finding: Finding): { endTime?: number, isEstimated?: boolean } {
  if(isSingularFinding(finding))
    throw new Error("getEndTimeForDurationFinding called for a finding with a single event!")

  if(finding.end_date)
    return { endTime: finding.end_date.getTime(), isEstimated: false }

  // Alert is considered firing, only if it fired during the last 6 hours (this is how prometheus works).
  // If it hasn't, then the alert is probably not firing anymore, and we're considering it as resolved.
  if (finding.latestRetransmission! < getMinFiringDate()) {
    const estimatedEndTime = DateTime.fromJSDate(finding.latestRetransmission!).plus({hours: 6}).toMillis()
    return { endTime: estimatedEndTime, isEstimated: true }
  }

  // Alert is still firing
  return { endTime: undefined, isEstimated: undefined }
}

export function getFormattedDate(d: Date | string | undefined | number) {
  if(!d)
    return ""
  return DateTime.fromJSDate(d).toFormat("DD HH:mm:ss")
}

export function getFindingEndTime(finding: Finding): Date | undefined {
  if(isSingularFinding(finding))
    return finding.start_date

  const { endTime } = getEndTimeForDurationFinding(finding)
  return endTime
    ? new Date(endTime)
    : undefined
}

export function isChangeFinding(finding: Finding) {
  return finding.nameCorrected === FindingName.CHANGE || finding.nameCorrected === FindingName.INGRESS_CHANGE
}

export function isChangeCreationFinding(finding: Finding) {
  return isChangeFinding(finding) && finding.title?.endsWith("created")
}

export function isChangeDeletionFinding(finding: Finding) {
  return isChangeFinding(finding) && finding.title?.endsWith("deleted")
}

export function getFindingType(finding: Finding): FindingType {
  if(isSingularFinding(finding)) {
    if(!isChangeFinding(finding))
      return FindingType.K8S
    if(isChangeCreationFinding(finding))
      return FindingType.CHANGE_CREATE
    if(isChangeDeletionFinding(finding))
      return FindingType.CHANGE_DELETE
    return FindingType.CHANGE_UPDATE
  }

  const endTime = getFindingEndTime(finding)

  if(endTime)
    return FindingType.RESOLVED

  if(finding.priority === "HIGH")
    return FindingType.FIRING_HIGH
  if(finding.priority === "MEDIUM")
    return FindingType.FIRING_MEDIUM
  if(finding.priority === "LOW")
    return FindingType.FIRING_LOW
  return FindingType.FIRING_INFO
}

export function getPriorityForFiringFindingType(type: FindingType): FindingPriority | undefined {
  if(type === FindingType.FIRING_HIGH)
    return FindingPriority.HIGH
  if(type === FindingType.FIRING_MEDIUM)
    return FindingPriority.MEDIUM
  if(type === FindingType.FIRING_LOW)
    return FindingPriority.LOW
  if(type === FindingType.FIRING_INFO)
    return FindingPriority.INFO
  return undefined
}